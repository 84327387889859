import Content from "./components/Content";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Offers from "./components/Offers";

function App() {
  return (
    <>
      <Hero />
      <Offers />
      <Content />
      <Footer />
    </>
  );
}

export default App;
